<template>
  <div class="assets-detail">
    <el-descriptions title="" :column="4" :colon="false">
      <template slot="title">
        <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
        <h4 class="descriptions-title">基础信息</h4>
      </template>

      <!--    <el-descriptions-item label="仪器序列号：" >{{form.instrument_id}}</el-descriptions-item>-->
      <!--    <el-descriptions-item label="仪器型号：">{{form.platform_unique}}</el-descriptions-item>-->
      <!--    <el-descriptions-item label="医院：">{{form.hospital_name}}</el-descriptions-item>-->
      <!--    <el-descriptions-item label="位置：">{{form.hos_pos}}</el-descriptions-item>-->
      <el-descriptions-item label="状态："><i class="status-tag" :class="handleStatus(form.asset_status_name).className"></i>{{form.asset_status_name}}</el-descriptions-item>
      <el-descriptions-item label="售后工程师：" span="3">{{form.engineer_name ? form.engineer_name : '/'}}</el-descriptions-item>
      <el-descriptions-item label="入库单号：">{{form.odd_no ? form.odd_no : '/'}}</el-descriptions-item>
      <el-descriptions-item label="入库时间：">{{form.odd_time? form.odd_time.slice(0,10) : '/'}}</el-descriptions-item>
      <el-descriptions-item label="入库操作人：" span="2">{{form.odd_user ? form.odd_user : '/'}}</el-descriptions-item>
      <el-descriptions-item label="出库单号：">{{form.checkout_no ? form.checkout_no :'/'}}</el-descriptions-item>
      <el-descriptions-item label="出库时间：">{{form.checkout_time ? form.checkout_time : '/'}}</el-descriptions-item>
      <el-descriptions-item label="出库操作人：" span="2">{{form.checkout_user_name ? form.checkout_user_name : '/'}}</el-descriptions-item>
      <el-descriptions-item label="装机时间：">{{form.install_date ? form.install_date : '/'}}</el-descriptions-item>
      <el-descriptions-item label="下次维保时间：" span="3">{{form.maintenance_date ? form.maintenance_date : '/' }}</el-descriptions-item>
      <el-descriptions-item label="上位机版本：">{{form.version_upper ? form.version_upper : '/'}}</el-descriptions-item>
      <el-descriptions-item label="下位机版本：">{{form.version_lower ? form.version_lower : '/'}}</el-descriptions-item>
      <el-descriptions-item label="进样架版本：">{{form.version_sample ? form.version_sample : '/'}}</el-descriptions-item>
      <el-descriptions-item label="GPRS版本：">{{form.version_gprs ? form.version_gprs : '/'}}</el-descriptions-item>
    </el-descriptions>

  <el-descriptions title="经销商信息" :column="1" :colon="false">
    <template slot="title">
      <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
      <h4 class="descriptions-title">经销商信息</h4>
    </template>
    <el-descriptions-item label="经销商：">{{form.customer_name ? form.customer_name : '/'}}</el-descriptions-item>
  </el-descriptions>
  <el-descriptions title="通讯设备运行信息" :column="8" :colon="false">
    <template slot="title">
      <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
      <h4 class="descriptions-title">通讯设备运行信息</h4>
    </template>
    <el-descriptions-item label="LAC：" v-if="['使用中','待返厂'].includes(form.instrument_status_name)">{{form.lac}}</el-descriptions-item>
    <el-descriptions-item label="CELLID：" v-if="['使用中','待返厂'].includes(form.instrument_status_name)" span="3" >
      <span style="vertical-align: top">{{form.cell_id}}</span>
      <el-button size="mini" class="map-btn" v-if="form.lac && form.cell_id"  @click="openMap">地图显示</el-button>
    </el-descriptions-item>
    <el-descriptions-item label="SIM卡：">{{form.sim_card ? form.sim_card : '/'}}</el-descriptions-item>
  </el-descriptions>
    <el-dialog
        title="地图显示"
        :visible.sync="dialogVisible"
        width="50%"
        top="30"
    >
      <mapping-positioning v-if="mapShow" :longitude='longitude' :latitude="latitude"></mapping-positioning>
      <span slot="footer" class="dialog-footer" style="text-align: center">
    <el-button @click="dialogVisible = false" size="small">关闭</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import {getAssetDtl} from '@/api/assetMgt'
import mappingPositioning from './mappingPositioning.vue'
import assetCommon from '../mixin/assetCommon'
export default {
  components:{mappingPositioning},
  mixins: [assetCommon],
  props:{
    isMapShow:{
      type:Boolean,
      default: false,
    },
    form:{
      type: Object,
      default: {},
    }
  },
  data(){
    return{
      dialogVisible: false,
      activeNames: ['1'],
      // longitude: '121.712264' , // 经度
      // latitude: '31.249972' , // 纬度
      longitude: '' , // 经度
      latitude: '' , // 纬度
      LAC: null,
      CELLID: null,
      mapShow: false,
      // 121.695140;31.234778;121.712264;31.249972
    }
  },
  created() {
  },
  methods:{
    openMap(){
      this.latitude = this.form.lat_lng.lat
      this.longitude = this.form.lat_lng.lng
      if(this.longitude && this.latitude){
        this.dialogVisible = true
        this.mapShow = true
      }else{
        this.$message.warning('暂时无法获取定位信息！')
      }
    },
  }
}

</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
.assets-detail{
  margin-top: 14px;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .map-btn{
    font-size: 13px!important;
    padding: 4px 8px!important;
    color: #409EFF;
    vertical-align: 0px;
    margin-left: 100px;
  }
}
@media screen and (min-width: 1551px), print {
  .map-btn{
    font-size: 14px!important;
    padding: 6px 12px!important;
    color: #409EFF;
    vertical-align: 0px;
    margin-left: 100px;
  }
}
.status-tag{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #00aeed;
  display: inline-block;
  margin-right: 4px;
  vertical-align: -1px;
}
//::v-deep .el-dialog__footer {
//  padding: 0 px(10) px(20) px(10);
//  text-align: center;
//  background: #fff;
//}
::v-deep .el-dialog__body{
  padding: 10px 20px ;
}
::v-deep .el-dialog__title{
  color: #409EFF;
  padding-bottom: 6px;
  border-bottom:2px solid #409EFF;
}
::v-deep .el-descriptions-item__container{
  color: #000;
}

</style>