<template>
  <div>
    <div class="">
      <div class="content-title">
        <p>物流信息</p>
      </div>
      <div v-if="page_type == 'wl' && (!express_no) && !express_com">
        <div style="margin-top: 0.75rem">
          <el-radio v-model="express_type" label="1">快递</el-radio>
          <el-radio v-model="express_type" v-if="$route.query.self_type=='zisong'" label="zisong">自送</el-radio>
          <el-radio v-model="express_type" v-if="$route.query.self_type=='ziti'" label="ziti">自提</el-radio>
        </div>
        <el-select v-if="express_type =='1'" v-model="express_company" placeholder="请选择物流公司"  size="small" style="margin-right: 10px">
          <el-option
              v-for="item in express_company_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-input type="input" v-if="express_type =='1'"  placeholder="请输入物流单号" v-model="shipping_code" size="small" style="width: auto;margin-right:16px;"></el-input>
        <el-button size="small" type="primary" class="lg-button" style="margin-top: 18px" @click="submit">提交</el-button>
        <el-button size="small" type="primary" v-if="express_type =='1'"  class="lg-button" @click="checkExpress">查看物流信息</el-button>
      </div>
      <div v-if="page_type == 'wl-check' ||  express_com">
        <p class="label_title" style="margin-bottom: 14px">物流方式：{{express_com == 'zisong' ? '自送' : express_com == 'ziti' ? '自提' : '快递'}}</p>
        <p v-if="!['zisong','ziti'].includes(express_com)">
          <span class="label_title" style="margin-right: 23px">物流公司： {{express_company_options.filter(item => item.value == this.express_com)[0].label}}</span>
          <span class="label_title">物流单号： {{express_no}}</span>
          <el-button size="mini" type="primary"  class="lg-button" @click="checkExpress" style="margin-left: 14px;">查看物流信息</el-button>
        </p>
      </div>
    </div>
    <el-dialog :visible="lgDialogVisible" title="物流信息" lock-scroll :before-close="handleClose" width="60%" height="500">
      <div class="lg-list">
        <ul>
          <li v-for="item in lgList">
            <span class="lg-time">{{item.time}}</span>
            <span class="lg-status">【{{item.status}}】</span>
            <p>{{item.context}}</p>
          </li>
        </ul>
        <div style="text-align:center;margin:20px 0 0 0; ">
          <span slot="footer" class="dialog-footer" >
            <el-button @click="lgDialogVisible = false" size="small">关闭</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {changeStatus, checkExpress, saveExpress} from "@/api/assetMgt";

export default{
  props:{
    instrument_status:{
      type: String,
      default:''
    },
    express_no:{
      type: String,
      default:''
    },
    express_com:{
      type: String,
      default:''
    },
    selfType:{
      type: String,
      default:''
    }
  },
  data(){
    return{
      express_type: '1',
      page_type: this.$route.query.page_type,
      lgDialogVisible: false,
      shipping_code: '',
      express_company: '',
      express_com: '',
      lgList:[],
      express_company_options:[
        {id:1001,label:'顺丰',value: 'shunfeng'},
        {id:1002,label:'京东',value: 'jingdong'},
        {id:1003,label:'韵达',value: 'yunda'},
        {id:1004,label:'极兔',value: 'jitu'},
        {id:1005,label:'中通',value: 'zhongtong'},
        {id:1006,label:'申通',value: 'shentong'},
        {id:1007,label:'圆通',value: 'yuantong'},
        {id:1008,label:'汇通',value: 'huitong'},
      ],
    }
  },
  created() {},
  methods:{
  submit(){
    let reg
    if(this.express_type == '1') {
      if (!this.express_company) {
        this.$message.error('请选择物流公司')
        return false
      } else if (!this.shipping_code) {
        this.$message.error('请填写快递单号')
        return false
      } else {
        if (this.express_company == 'shunfeng') {
          reg = /^SF[A-Za-z0-9]{13}$/
          // reg = /^[A-Za-z0-9]{4,35}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('顺丰物流单号格式不正确')
            return false
          }
        } else if (this.express_company == 'jingdong') {
          reg = /^JD[A-Za-z0-9]{13}$/
          // reg = /^[A-Za-z0-9]{12,15}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('京东物流单号格式不正确')
            return
          }
        } else if (this.express_company == 'yunda') {
          reg = /^[0-9]{12,15}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('韵达物流单号格式不正确')
            return
          }
        } else if (this.express_company == 'jitu') {
          reg = /^JT\d{13}$/
          // reg = /^[A-Za-z0-9]{11,20}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('极兔物流单号格式不正确')
            return
          }
        } else if (this.express_company == 'zhongtong') {
          reg = /^[0-9]{12}$|^[0-9]{14}$|^[0-9]{15}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('中通物流单号格式不正确')
            return
          }
        } else if (this.express_company == 'shentong') {
          reg = /^[0-9]{12,15}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('申通物流单号格式不正确')
            return
          }
        } else if (this.express_company == 'yuantong') {
          reg = /^(YT)[0-9]{12,15}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('圆通物流单号格式不正确')
            return
          }
        } else if (this.express_company == 'huitong') {
          reg = /^\d{13}$/
          if (!reg.test(this.shipping_code)) {
            this.$message.error('汇通物流单号格式不正确')
            return
          }
        }
      }
    }
    let express
    let instrument_status = this.$route.query.instrument_status
    if(instrument_status == '待发货'){ //出库
      express = 1201
    }else if(instrument_status == '返厂中'){  // 返厂维修快递
      express = 1202
    }else if(['已维修'].includes(instrument_status) ){  //返厂维修（出厂）快递"
      express = 1203
    }else if(instrument_status == '报废中'){  //"返厂报废快递
      express = 1204
    }
    let paramas = {
      express_class: express,
      instrument_id: this.$route.query.instrument_id
    }
    if(this.express_type == '1'){
      paramas.express_com = this.express_company
      paramas.shipping_code = this.shipping_code
    }else{
      paramas.express_com = this.express_type
    }
    const h = this.$createElement
    this.$confirm('提示', {
      title: '提示',
      message: h('div', [h('p', `确认提交物流信息吗？`),]),
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      const loading = this.$loading({
        lock: true,
        text: '提交中...' ,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      saveExpress(paramas).then(res=>{
        if(res.code == 200){
          this.$message.success('提交成功！')
        }
        loading.close()
        this.$router.push({path: '/assetMgt/list'})
      }).catch(err=>{
        loading.close()
      })
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '已取消确认',
      })
    })
    },
    checkExpress(){
      let params = {
        // shipping_code: 'SF1626103625979',
        // instrument_id: '10K06017',
        instrument_id: this.$route.query.instrument_id,
        shipping_code: this.shipping_code ||this.express_no,
      }
      checkExpress(params).then(res => {
        this.lgList = res.data
        if(this.lgList.length > 0 && typeof this.lgList != 'string'){
          this.lgDialogVisible = true
        }else{
          this.$message.warning(res.data)
        }

      })
    },
    handleClose(){
      this.lgDialogVisible = false
    },
  }
}
</script>


<style scoped lang="scss">
@import '@/assets/css/asset';
.lg-button{
  padding:8px 30px;
  margin: 0rem 20px 20px 0;
}
.lg-list{
  min-height: 400px;
  max-height: 500px;
  overflow: auto;
  ul li{
    //height: 50px;
    line-height: 28px;
    padding: 8px 10px;
    span{
      vertical-align: top;
    }
    .lg-time{
      margin-right: 6px;
    }
    .lg-status{
      margin-right: 36px;
    }
    p{
      display: inline-block;
      width: 60%
    }
  }
  ul li:nth-child(odd){
    background: #eee;
  }
}
::v-deep  .el-dialog__title{
  color: #00A2E5;
  padding-bottom: 6px;
  border-bottom: 2px solid #00A2E5;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .label_title{
    font-size: 14px;
  }
}
@media screen and (min-width: 1551px), print {
  .label_title{
    font-size: 16px;
  }
}
</style>