<template>
  <div class="sheet">
    <el-table :data="tableData" stripe :height="tableHeight" v-loading="loading">
      <el-table-column prop="create_time" min-width="180"  label="时间" align="center">
        <template v-slot="{row}">
          <span>{{row.create_time ? row.create_time : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="opt_class_name" min-width="130"  label="流转事件" align="center">
        <template v-slot="{row}">
          <span>{{row.opt_class_name ? row.opt_class_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hospital_name" min-width="250"  label="医院/经销商" align="center">
        <template v-slot="{row}">
          <span>{{row.hospital_name ? row.hospital_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="label" width="120"  label="位置" align="center">
        <template v-slot="{row}">
          <span>{{row.label ? row.label : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="opt_user_name" min-width="100"  label="操作人员" align="center">
        <template v-slot="{row}">
          <span>{{row.opt_user_name ? row.opt_user_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" min-width="180"  label="备注" align="center">
        <template v-slot="{row}">
          <div v-if="row.opt_class_name=='返厂'">
            <span style="text-align: left">{{row.remarks ? row.remarks.remarks : '/'}}</span>
            <!--          <span v-if="row.remarks.attachments && row.remarks.attachments.length > 0" style="margin-right: 10px" v-for="item in row.remarks.attachments" >{{item.split('/')[item.split('/').length - 1] }}</span>-->
            <p v-if="row.remarks && row.remarks.attachments" class="download-link">
              <el-link type="primary" @click="download(item)"  v-for="item in row.remarks.attachments">{{item.split('/')[item.split('/').length - 1] }}</el-link>
            </p>
          </div>
          <div v-if="row.opt_class_name !=='返厂'">
            <span style="text-align: left">{{row.remarks ? row.remarks : '/'}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作 " min-width="130" align="center" fixed="right">
        <template v-slot="{row}">
          <el-button size="mini" style="color: #409EFF" @click="handleCheck(row)" v-if="row.scrap_pdf_url || row.install_pdf_url || row.checkout_pdf">
            {{row.install_pdf_url ? '查看装机单' :  row.scrap_pdf_url ? '查看报废单' : row.checkout_pdf ? '查看出库单' : ''}}</el-button>
          <el-button size="mini" v-else-if="row.application_pdf" style="color: #409EFF" @click="handleCheck(row)">返厂申请单</el-button>
          <span v-else>/</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="el_pagination_style">
      <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%"
        top="10px"
    >
      <embed
          id="printMe"
          :src="pdf_file"
          type="application/pdf"
          width="100%"
          height="100%" />
      <span slot="footer" class="dialog-footer" style="text-align: center">
<!--        <el-button @click="dialogVisible = false" size="small">关闭</el-button>-->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PlatSelect from "@/components/PlatSelect.vue";
import {getAssetCirculation} from '@/api/assetMgt'
import assetCommon from "@/views/AssetManagement/mixin/assetCommon";
export default {
  name: 'transferRecords',
  mixins: [assetCommon],
  data() {
    return {
      loading: false,
      province_id: '', //省份标识
      platform_id: '', //平台标识
      district_id: '', //地区选择-值
      total: 0,
      page: 1,
      tableData:[],
      dialogVisible: false,
      pdf_file: '',
      dialogTitle: '',
      tableHeight: 0
    }
  },
  created() {
    this.getTableList()
    this.getTableHeight()
  },
  methods: {
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      var viewportWidth =  window.innerWidth
      var element = document.getElementById('pagination');
      if(viewportWidth > 2400){
        this.tableHeight = 450
      }else if(viewportWidth < 2400 &&  viewportWidth > 1550){
        this.tableHeight = 418
      }else{
        this.tableHeight = 312
      }
    },
    handleCheck(row){
      if(row.scrap_pdf_url){
        this.dialogVisible = true
        this.pdf_file = row.scrap_pdf_url
        this.dialogTitle = '查看报废单'
      }else if(row.install_pdf_url){
        this.dialogVisible = true
        this.pdf_file = row.install_pdf_url
        this.dialogTitle = '查看装机单'
      }else if(row.checkout_pdf){
        this.dialogVisible = true
        this.pdf_file = row.checkout_pdf
        this.dialogTitle = '查看出库单'
      }else if(row.application_pdf){
        this.dialogVisible = true
        this.pdf_file = row.application_pdf
      } else{
        this.$message.warning('暂无信息！')
      }
    },
    getTableList(){
      this.loading = true
      let params = {
        page: this.page,
        // size: 10,
        instrument_id: this.$route.query.instrument_id
        // instrument_id: '10K06017'
      }
      getAssetCirculation(params).then(res => {
        this.tableData = res.data.data
        this.total = res.data.count
      }).finally(() => {
        this.loading = false
      })
    },
    download(endUrl){
      let url = endUrl
      this.downloadFile(url)
    },
    handleCurrentChange(val){
      this.page = val
      this.getTableList();
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title{
  color: #409eff;
  border-bottom: 2px solid #409eff;
  padding-bottom: 8px;
}
::v-deep .el-dialog{
  height: 99%;
  .el-dialog__body{
    height: 86%;
  }
}
::v-deep .el-table__fixed-right::before{
  width: 0!important;
}
.download-link{
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .download-link .el-link.el-link--primary{
    font-size: 13px;
    text-align: left;
  }
}
@media screen and (min-width: 1551px) , print{
  .download-link .el-link.el-link--primary{
    font-size: 15px;
    text-align: left;
  }
}
</style>