<template>
  <div id="map" style="width:100%; height: 500px;"></div>
</template>

<script>
import { Map } from 'vue-amap';

export default {
  name: 'MapMarker',
  components: { Map },
  props:{
    longitude:{
      type: Number,
      default: null
    },
    latitude:{
      type: Number,
      default: null
    },
  },
  data() {
    return {
      center:[121.704499,31.240316] , //
      markerPosition:[121.704499 ,31.240316]  // 标记点坐标
      // markerPosition:[121.704499 ,31.240316]  // 标记点坐标
    };
  },
  created() {
    this.center = [this.latitude,this.longitude];
    this.markerPosition = [this.latitude,this.longitude];
  },
  mounted() {
    // 初始化高德地图
    this.$nextTick(() => {
      const map = new AMap.Map('map', {
        zoom: 18, // 缩放级别
        center: this.center // 中心点坐标
      });

      // 在地图上添加标记
      const marker = new AMap.Marker({
        position: this.markerPosition,
        map: map
      });

      // 将标记显示在地图上
      marker.setMap(map);
    });
  }
};
</script>