var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sheet"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData,"stripe":"","height":_vm.tableHeight}},[_c('el-table-column',{attrs:{"prop":"create_time","min-width":"180","label":"时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.create_time ? row.create_time : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"opt_class_name","min-width":"130","label":"流转事件","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.opt_class_name ? row.opt_class_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_name","min-width":"250","label":"医院/经销商","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.hospital_name ? row.hospital_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"label","width":"120","label":"位置","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.label ? row.label : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"opt_user_name","min-width":"100","label":"操作人员","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.opt_user_name ? row.opt_user_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","min-width":"180","label":"备注","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.opt_class_name=='返厂')?_c('div',[_c('span',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(row.remarks ? row.remarks.remarks : '/'))]),(row.remarks && row.remarks.attachments)?_c('p',{staticClass:"download-link"},_vm._l((row.remarks.attachments),function(item){return _c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.download(item)}}},[_vm._v(_vm._s(item.split('/')[item.split('/').length - 1]))])}),1):_vm._e()]):_vm._e(),(row.opt_class_name !=='返厂')?_c('div',[_c('span',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(row.remarks ? row.remarks : '/'))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作 ","min-width":"130","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.scrap_pdf_url || row.install_pdf_url || row.checkout_pdf)?_c('el-button',{staticStyle:{"color":"#409EFF"},attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleCheck(row)}}},[_vm._v(" "+_vm._s(row.install_pdf_url ? '查看装机单' : row.scrap_pdf_url ? '查看报废单' : row.checkout_pdf ? '查看出库单' : ''))]):(row.application_pdf)?_c('el-button',{staticStyle:{"color":"#409EFF"},attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleCheck(row)}}},[_vm._v("返厂申请单")]):_c('span',[_vm._v("/")])]}}])})],1),_c('div',{staticClass:"el_pagination_style"},[_c('el-pagination',{attrs:{"hide-on-single-page":_vm.total <= 0,"background":"","layout":"total, prev, pager, next, jumper","total":_vm.total,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.dialogVisible,"width":"60%","top":"10px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('embed',{attrs:{"id":"printMe","src":_vm.pdf_file,"type":"application/pdf","width":"100%","height":"100%"}}),_c('span',{staticClass:"dialog-footer",staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }