<template>
  <div class="sheet">
    <el-table :data="tableData" :height="tableHeight" stripe v-loading="loading">
      <el-table-column prop="applicant" width="130"  label="发起人" align="center">
        <template v-slot="{row}">
          <span>{{row.applicant ? row.applicant : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="implement_engineer_name" width="130"  label="维修人" align="center">
        <template v-slot="{row}">
          <span>{{row.implement_engineer_name ? row.implement_engineer_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="implement_date" min-width="180"  label="维保时间" align="center">
        <template v-slot="{row}">
          <span>{{row.implement_date ? row.implement_date : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="task_type_name" width="130"  label="维保类型" align="center">
        <template v-slot="{row}">
          <span>{{row.task_type_name ? row.task_type_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="fault_type_name" min-width="130"  label="故障类型" align="center">
        <template v-slot="{row}">
          <span>{{row.fault_type_name ? row.fault_type_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作 " min-width="130" align="center" fixed="right">
        <template v-slot="{row}">
          <el-button size="mini" v-if="row.repair_pdf" style="color: #409EFF" @click="checkRepairOrder(row,'查看维修单')">查看维修单</el-button>
          <el-button size="mini"  v-else-if="row.maintenance_pdf"  style="color: #409EFF" @click="checkRepairOrder(row,'查看保养单')">查看保养单</el-button>
        <span v-else>/</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="el_pagination_style">
      <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%"
        top="10px"
    >
      <embed
          id="printMe"
          :src="pdf_file"
          type="application/pdf"
          width="100%"
          height="100%" />
      <span slot="footer" class="dialog-footer" style="text-align: center">
<!--        <el-button @click="dialogVisible = false" size="small">关闭</el-button>-->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import jsPDF from 'jspdf'
import { getBase64 } from '@/utils/common'
import PlatSelect from "@/components/PlatSelect.vue";
import {getMaintenanceRecords} from '@/api/assetMgt'
export default {
  name: 'maintenanceRecords',
  data() {
    return {
      loading: false,
      total: 0,
      page: 1,
      province_id: '', //省份标识
      platform_id: '', //平台标识
      district_id: '', //地区选择-值
      tableData:[],
      dialogVisible: false,
      pdf_file: '',
      dialogTitle: '',
      tableHeight:0,
    }
  },
  created() {
    this.getTableList()
    this.getTableHeight()
  },
  methods: {
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      var viewportWidth =  window.innerWidth
      // var element = document.getElementById('pagination');
      if(viewportWidth > 2400){
        this.tableHeight = 450
      }else if(viewportWidth < 2400 &&  viewportWidth > 1550){
        this.tableHeight = 418
      }else{
        this.tableHeight = 312
      }
    },
    checkRepairOrder(row,name){
      if(row.repair_pdf){
        this.dialogVisible = true
        this.pdf_file = row.repair_pdf.includes('http') ? row.repair_pdf : preUrl + row.repair_pdf
        // this.pdf_file = row.repair_pdf
        this.dialogTitle = name
      }else if(row.maintenance_pdf){
        this.dialogVisible = true
        this.pdf_file = row.maintenance_pdf.includes('http') ? row.maintenance_pdf : preUrl + row.maintenance_pdf
        // this.pdf_file = row.maintenance_pdf
        this.dialogTitle = name
      }else{
        this.$message.warning('暂无信息！')
      }
    },
    getTableList(){
      this.loading = true
      let params = {
        page: this.page,
        // size: 10,
        instrument_id: this.$route.query.instrument_id
        // instrument_id: '10K06017'
      }
      getMaintenanceRecords(params).then(res => {
        this.tableData = res.data.data
        this.total = res.data.count
        this.loading = false
      }).catch(err=> {
        this.loading = false
      })
    },
    handleCurrentChange(val){
      this.page = val
      this.getTableList()

    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title{
  color: #409eff;
  border-bottom: 2px solid #409eff;
  padding-bottom: 8px;
}
::v-deep .el-table__fixed-right::before{
  width: 0!important;
}
::v-deep .el-dialog{
  height: 99%;
  .el-dialog__body{
    height: 86%;
  }
}
</style>
