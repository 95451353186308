<template>
  <div class="zone1">
    <el-breadcrumb separator="/" class="breadcrumb" style="display: inline-block;" v-if="isBreadcrumb">
      <el-breadcrumb-item style="">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item>资产详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-link size="mini" type="primary"  icon="el-icon-back" class="back-btn" v-if="isBreadcrumb" @click="goBack">返回上一页</el-link>
    <template>
      <div :class="isBreadcrumb ? 'content-block' :''" style="padding-top:0">
        <div :class="isBreadcrumb ? 'is-breadcrumb' : ''"  class="top-form">
          <el-descriptions title="" :column="5" :colon="false">
            <el-descriptions-item label="仪器序列号：">{{form.instrument_id ? form.instrument_id : '/'}}</el-descriptions-item>
            <el-descriptions-item label="仪器型号：">{{form.platform_unique ? form.platform_unique : '/'}}</el-descriptions-item>
            <el-descriptions-item label="医院："  span="2">{{form.hospital_name ? form.hospital_name : '/'}}</el-descriptions-item>
            <el-descriptions-item label="位置：">{{form.hos_pos ? form.hos_pos : '/'}}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="content-title" v-if="!isBreadcrumb">
          <p >资产详情</p>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="资产信息" name="1">
          <assets-detail ref="assetsDtl" :isMapShow="true" :form="form"></assets-detail>
        </el-tab-pane>
        <el-tab-pane label="维保记录" name="2">
          <maintenance-records></maintenance-records>
        </el-tab-pane>
        <el-tab-pane label="流转记录" name="3">
          <transferRecords></transferRecords>
        </el-tab-pane>
      </el-tabs>
      </div>
    </template>
  </div>
</template>

<script>

import TJDetailTitle from '@/components/TJDetailTitle'
import { getdosage_nationwide } from '@/router/menuMain.js'
import AssetsDetail from "@/views/AssetManagement/components/assetsDetail.vue";
import MaintenanceRecords from "@/views/AssetManagement/components/maintenanceRecords.vue";
import TransferRecords from "@/views/AssetManagement/components/transferRecords.vue";
import {getAssetDtl} from "@/api/assetMgt";
import logisticsCheck from "@/views/AssetManagement/components/logisticsCheck.vue";

export default {
  name: 'amDetail', // 资产详情
  components: {logisticsCheck, TransferRecords, MaintenanceRecords, AssetsDetail, TJDetailTitle},
  props:{
    isBreadcrumb:{
      type:Boolean,
      default: true
    }
  },
  data() {
    return {
      activeName: '1',
      form: {},
    }
  },
  computed: {
    serialtext() {
      return (index)=>{
        if(this.dosage_nationwide){
          if(index == 0) {
            return 0
          }else{
            return (this.page-1)*10+index
          }
        }else{
          return (this.page-1)*10+index+1
        }
      }
    },
  },
  created() {
    this.getDtl()
  },
  mounted() {
    // 延迟来让时间组件加载出来
    setTimeout(() => {
    }, 200);
  },
  methods: {
    getDtl(){
      const loading = this.$loading({
        lock: true,
        text: '数据正在努力加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getAssetDtl({instrument_id: this.$route.query.instrument_id,is_check: this.$route.query.page_type== 'wl' ? 0 : 1}).then(res => {
        this.form = res.data
        if(this.form.lat_lng){
          this.longitude = this.form.lat_lng.lng
          this.latitude = this.form.lat_lng.lat
        }
        loading.close()
        setTimeout(() => {
          this.$emit('getForm', res.data)
        }, 1000);
      }).catch(err=>{
        loading.close();
      })
    },
    handleClick(tab, event) {
    },
    goBack(){
      this.$router.push({ path: '/assetMgt/list' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/asset';
.is-breadcrumb{
  padding: 18px 0 0 0;
}

.indent {
  //height: px(121);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
  .indentone {
    width: 100%;
    height: px(56);
    display: flex;
    border-bottom: 1px solid #d0d7e0;
    margin-bottom: px(15);

    .NumCla{
      justify-content: end;
      width: 80%;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #111111;
      margin-right: px(20);
    }
    .cursor {
      cursor: pointer;
    }
    .left {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #111111;
      margin-right: px(20);
    }
    .toDevice {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #666666ff;
      margin-right: px(20);
    }
    .tohospital {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #666666ff;
      margin-right: px(20);
    }

    .line {
      width: 1px;
      height: 13px;
      background-color: rgba(208, 215, 224, 1);
      margin-top: px(16);
      margin-right: px(20);
    }
  }
  .below {
    display: flex;
    .calendar {
      margin-left: px(10);
    }
    .cityProvince {
      margin-left: px(10);
    }
    ::v-deep .cityProvince .el-input--small .el-input__inner {
      width: px(220);
    }
    .calendarone {
      display: flex;
      margin-left: px(10);
    }
    .seachone {
      margin-left: px(10);
      .exportExcle{
        margin-left: px(50);
      }
    }
  }
}
.sheet {
  //min-height: px(793);
  border-radius: px(10);
  background-color: #fff;
  margin-bottom: px(23);

  .el-table {
    .caozuo {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 40px;
      color: #d0d7e0;
      cursor: pointer;
      .box {
        width: 36px;
        height: 36px;
      }
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
.custom-tooltip-border{
  border: 1px solid #8f9297!important;
}
.zone1{
  padding: px(8)  px(24);
}
::v-deep .el-tabs__header{
  margin: 0;
}
::v-deep .el-descriptions-item__container{
  color: #000;
}

</style>
